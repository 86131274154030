<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!----------------------------------------POLICY NUMBERS----------------------------------------->
    <base-material-card
      color="primary"
      icon="mdi-chart-pie"
      title="Business Intel Reports"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
        >
          <v-autocomplete
            v-model="selectedUser"
            :autocomplete="Date.now()"
            :items="userlist"
            item-text="Name"
            outlined
            color="blue-grey lighten-2"
            label="Select A User"
            return-object
            @change="showSelectedData(selectedUser)"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      color="primary"
      icon="mdi-file-table"
      title="BI Report Detail"
      class="px-4 py-3"
    >
      <v-row v-if="selectedUser">
        <v-col
          cols="12"
          md="12"
        >
          <base-material-card
            class="v-card-profile"
            avatar="/img/mitchellprofile.jpeg"
          >
            <v-card-text class="text-center">
              <h6 class="text-h4 mb-1 grey--text">
                CTO Blanket.com
              </h6>

              <h4 class="text-h3 font-weight-light mb-3 black--text">
                {{ selectedUser.Name }} | {{ selectedUser.Age }}
              </h4>

              <v-col
                cols="12"
                class="d-inline-flex justify-center"
              >
                <v-icon
                  class="px-2"
                  color="indigo"
                  large
                >
                  mdi-facebook
                </v-icon>
                <v-icon
                  class="px-2"
                  color="black"
                  large
                >
                  mdi-linkedin
                </v-icon>
                <v-icon
                  class="px-2"
                  color="primary"
                  large
                >
                  mdi-twitter
                </v-icon>
                <v-icon
                  class="px-2"
                  color="green"
                  large
                >
                  mdi-instagram
                </v-icon>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-col
                cols="4"
              >
                <base-material-card
                  color="primary"
                  icon="mdi-map"
                  title="Location"
                  class="ma-4 pa-4"
                  min-height="240"
                >
                  <v-list>
                    <v-list-item-group
                      color="primary"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedUser.Address.Street }}</v-list-item-title>
                          <v-list-item-subtitle>Street</v-list-item-subtitle>

                          <v-list-item-title>{{ selectedUser.Address.City }}</v-list-item-title>
                          <v-list-item-subtitle>City</v-list-item-subtitle>

                          <v-list-item-title>{{ selectedUser.Address.Country }}</v-list-item-title>
                          <v-list-item-subtitle>Country</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </base-material-card>
              </v-col>
              <v-col
                cols="4"
              >
                <base-material-card
                  color="primary"
                  icon="mdi-account"
                  title="Last Job Titles"
                  class="ma-4 pa-4"
                >
                  <v-list dense>
                    <v-list-item-group
                      v-model="selectedItem"
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in selectedUser.JobTitles"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.Title"></v-list-item-title>
                          <v-list-item-subtitle>
                            Employees: {{ item.employees }} | {{ item.date }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </base-material-card>
              </v-col>
              <v-col
                cols="4"
              >
                <base-material-card
                  color="primary"
                  icon="mdi-robot"
                  title="Machine Learning"
                  class="ma-4 pa-4"
                >
                  <v-list dense>
                    <v-list-item-group
                      v-model="selectedItem"
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in selectedUser.Recommend"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.PolicyType"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.detail"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </base-material-card>
              </v-col>
            </v-card-actions>
          </base-material-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="green"
            icon="mdi-poll"
            title="Website Visits"
            value="46"
            sub-icon="mdi-tag"
            sub-text="Tracked from Google Analytics"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="black"
            icon="mdi-cash-check"
            title="Premium"
            value="$ 2,245"
            sub-icon="mdi-calendar"
            sub-text="Last 2 Years"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="orange"
            icon="mdi-ballot-outline"
            title="Policies"
            value="4"
            sub-icon="mdi-calendar"
            sub-text="Last 2 Years"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-card>
            <v-data-table
              :headers="policyHeaders"
              :items="selectedUser.policies"
              item-key="policyNum"
              class="elevation-1 custom_table_class"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>

  export default {

    data: () => ({
      policyHeaders: [
        { text: 'Created', value: 'date', align: 'center', sortable: false },
        { text: 'Policy Type', value: 'PolicyType', sortable: true },
        { text: 'Amount', value: 'Cost', align: 'center', sortable: false },
        { text: 'Payment Method', value: 'PaymentMethod', align: 'center', sortable: false },
      ],
      selectedUser: '',
      policyData: [{ year: '2022', quarter: 'Q1', week1: 230, week2: 310, week3: 400, week4: 1010, week5: 400, week6: 360, week7: 320, week8: 230, week9: 140, week10: 280, total: '3680' }],
      years: ['2022'],
      year: '',
      currentYear: '',
      quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
      quarter: 'Q1',
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Week 10'],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }

        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
      },
      policiesByTypeSeries: [],
      policiesByTypeOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10'],
          labels: {
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    }),
    computed: {
      userlist () {
        let userRec = {
          Name: 'Mitchell Rankin',
          ProfileUrl: 'stringUrl',
          UserCreated: '2020-08-02',
          Age: '46',
          Birthdate: '1976-05-27',
          linkedin: 'url',
          facebookId: 'FbID',
          Insta: 'InstaId',
          MaritalStatus: 'Married',
          Address: {
            Street: 'Tartu Mnt 18-54',
            City: 'Tallinn',
            Country: 'Estonia',
          },
          Recommend: [
            { PolicyType: 'Travel', detail: 'Frequent Travel' },
            { PolicyType: 'Home Owner', detail: 'Owns Home over 5 years' },
            { PolicyType: 'Pet', detail: 'Likely to have a dog' },
          ],
          JobTitles: [
            { Title: 'CTO Blanket.com', date: '2022-04-10', employees: '22' },
            { Title: 'CTO Project Systems', date: '2014-02-10', employees: '63' },
            { Title: 'Head Of Engineering Scientific Games', date: '2010-04-10', employees: '9000' },
          ],
          policies: [
            { PolicyType: 'Travel', Cost: '$200.00', date: '2022-01-23', PaymentMethod: 'Card' },
            { PolicyType: 'Travel', Cost: '$100.00', date: '2021-12-23', PaymentMethod: 'Check' },
            { PolicyType: 'Pet', Cost: '$200.00/Month', date: '2022-01-23', PaymentMethod: 'Card' },
            { PolicyType: 'Travel', Cost: '$200.00', date: '2021-11-31', PaymentMethod: 'Card' },
          ]
        }
        let userlist = []
        userlist.push(userRec)
        return userlist
      }
    },
    created () {
    },
    methods: {
      showSelectedData (item) {
        // Return policy summary
        console.log(item)
      }
    }
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
</style>
